jQuery(function () {
  $('.card-box').on('change', '.selected-checkbox', function() {
    const $closest_input = $(this).parents('tr').find('.desired-amount-input');

    sumInstallments();

    toggleDisableElement($closest_input);

    toggleDisableButton();
  });

  $('.card-box').on('change', '.desired-amount-input', function () {
    sumInstallments();
  });
  sumInstallments();
  toggleDisableButton();
});

function sumInstallments() {
  let sum = 0;

  $('.desired-amount-input').each(function() {
    const $closest_checkbox = $(this).parents('tr').find('.selected-checkbox');

    const amount = parseFloat(unMask($(this).val()));

    if ($closest_checkbox[0].checked && amount > 0) {
      sum += amount;
    }
  });

  if(sum > 0) {
    $('.total')
      .html(`<span>Total do empréstimo: <strong class="installment-strong">${sum.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></span>`);
  } else {
    $('.total').html('');
  }
};

function unMask(value) {
  return value.replace(/\./g, '').replace(',', '.');
};

function toggleDisableElement(element) {
  $(element).prop('disabled', (_i, v) => !v);
};

function toggleDisableButton() {
  const $button = $('#submit');
  let anyChecked = $.find('.selected-checkbox:checked').length > 0;

  if (anyChecked && $button.prop("disabled")) {
    toggleDisableElement($button);
  } else if (!anyChecked && !$button.prop("disabled")) {
    toggleDisableElement($button);
  }
};
