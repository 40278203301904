import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const DocumentInput = ({ label, name, type, placeholder, onChange, required, errorMessage, onBlur, disabled }) => {
  const [file, setFile] = useState(null);

  const onButtonClick = () => {
    document.getElementById(name).click();
  }

  const removeFile = () => {
    setFile(null);
    document.getElementById(name).value = '';
    onChange({
      target: {
        name,
        value: ''
      }
    });
  }

  useEffect(() => {
    const inputElement = document.getElementById(name);
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      onChange({
        target: {
          name,
          value: selectedFile
        }
      });
    };

    inputElement.addEventListener('change', handleFileChange);

    return () => {
      inputElement.removeEventListener('change', handleFileChange);
    };
  }, [name, onChange]);

  return (
    <div className="form-group card p-2">
      <label htmlFor={name} className="mb-2 text-dark">{label}</label>
      {!file && <button type="button" className="btn btn-primary-light" onClick={onButtonClick}>Escolher documento</button>}
      {file && (
        <div className="p-2 border rounded d-flex flex-row">
          <p className="text-dark overflow-hidden w-100">{file.name}</p>
          <button type="button" onClick={removeFile} className="no-style">
            <i className="fas fa-times-circle"></i>
          </button>
        </div>
      )}
      <input
        id={name}
        type={type}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        hidden={true}
        className="form-control"
      />
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
}

export default DocumentInput;

DocumentInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
}
