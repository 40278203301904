import React, { useState } from 'react';
import { render, screen, fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect';
import Checkbox from './Checkbox';

describe('Checkbox component', () => {
  test('should render with label', () => {
    render(<Checkbox label="My Checkbox" />);
    expect(screen.getByText('My Checkbox')).toBeInTheDocument();
  });

  test('should have initial checked state', () => {
    render(<Checkbox label="My Checkbox" checked={true} />);
    const checkbox = screen.getByRole('checkbox');
    expect(checkbox).toBeChecked();
  });

  test('should toggle checked state on change', () => {
    const TestComponent = () => {
      const [checked, setChecked] = useState(false);
      return (
        <Checkbox
          label="My Checkbox"
          checked={checked}
          onChange={setChecked}
        />
      );
    };

    render(<TestComponent />);
    const checkbox = screen.getByRole('checkbox');

    fireEvent.click(checkbox);
    expect(checkbox).toBeChecked();

    fireEvent.click(checkbox);
    expect(checkbox).not.toBeChecked();
  });

  test('should have the correct name', () => {
    render(<Checkbox label="My Checkbox" name="myCheckbox" />);
    const checkbox = screen.getByRole('checkbox');
    expect(checkbox).toHaveAttribute('name', 'myCheckbox');
  });
});
