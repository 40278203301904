import React, { useState, useCallback, useRef } from "react";
import Input from "../Input";
import Button from "../Button";
import PropTypes from "prop-types";
import { onFormSubmit } from "./utils";

const LoginPage = ({
  loginUrl,
  ssoUrl,
  passwordNewUrl,
  passwordResetUrl,
  passwordUnlockUrl,
  authToken,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const formRef = useRef(null);

  const onSubmit = useCallback(
    (event) => {
      onFormSubmit(event, formRef, authToken);
    },
    [authToken]
  );

  const onSecondaryButtonClick = useCallback(() => {
    window.location.href = ssoUrl;
  }, [ssoUrl]);

  return (
    <div>
      <div className="card">
        <form
          className="card-body d-flex flex-column gap-3"
          onSubmit={onSubmit}
          action={loginUrl}
          method="POST"
          ref={formRef}
        >
          <div className="d-flex flex-column">
            <Input
              label="Email"
              type="email"
              name="email"
              placeholder="E-mail"
              required
              aria-label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              label="Senha"
              type="password"
              name="password"
              placeholder="Senha"
              required
              aria-label="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <Button
              className="mb-2"
              text="Entrar"
              type="submit"
              variant="primary"
              aria-label="Entrar"
            />
            <Button
              text="Entrar com SSO"
              type="button"
              variant="secondary"
              onClick={onSecondaryButtonClick}
              aria-label="Entrar com SSO"
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            <a href={passwordNewUrl}>Primeiro acesso?</a>
            <a href={passwordResetUrl}>Esqueceu sua senha?</a>
            <a href={passwordUnlockUrl}>Não recebeu instruções de desbloqueio?</a>
          </div>
        </form>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  loginUrl: PropTypes.string.isRequired,
  ssoUrl: PropTypes.string.isRequired,
  passwordNewUrl: PropTypes.string.isRequired,
  passwordResetUrl: PropTypes.string.isRequired,
  passwordUnlockUrl: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
};

export default LoginPage;
