import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ label = "", checked = false, onChange = () => {}, name = "" }) => {
  const onCheckboxChange = (e) => {
    onChange(e.target.checked);
  }

  return (
    <label className="custom-label" aria-labelledby={name}>
      <input
        className="custom-checkbox"
        type="checkbox"
        checked={checked}
        onChange={onCheckboxChange}
        name={name}
        aria-checked={checked}
      />
      <span className="checkbox-checkmark"></span>
      <span className="checkbox-label text-dark">{label}</span>
    </label>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string
};

export default Checkbox;
