import React from 'react';
import { render, screen } from '@testing-library/react';
import ProposalStatusBadge from './ProposalStatusBadge';

describe('ProposalStatusBadge', () => {
  const renderComponent = (statusInfo) => {
    render(<ProposalStatusBadge statusInfo={statusInfo} />);
  };

  it('renders the correct icon and status for alert badge', () => {
    const statusInfo = { badge: 'alert', status: 'With Pendency' };
    renderComponent(statusInfo);

    const icon = screen.getByText('With Pendency').parentElement.querySelector('.fa-exclamation-triangle');
    expect(icon).toBeInTheDocument();
    expect(screen.getByText('With Pendency')).toBeInTheDocument();
    expect(screen.getByText('With Pendency')).toHaveClass('feedback-tag-alert');
  });

  it('renders the correct icon and status for success badge', () => {
    const statusInfo = { badge: 'success', status: 'Paid' };
    renderComponent(statusInfo);

    const icon = screen.getByText('Paid').parentElement.querySelector('.fa-check-circle');
    expect(icon).toBeInTheDocument();
    expect(screen.getByText('Paid')).toBeInTheDocument();
    expect(screen.getByText('Paid')).toHaveClass('feedback-tag-success');
  });

  it('renders the correct icon and status for danger badge', () => {
    const statusInfo = { badge: 'danger', status: 'Canceled' };
    renderComponent(statusInfo);

    const icon = screen.getByText('Canceled').parentElement.querySelector('.fa-times-circle');
    expect(icon).toBeInTheDocument();
    expect(screen.getByText('Canceled')).toBeInTheDocument();
    expect(screen.getByText('Canceled')).toHaveClass('feedback-tag-danger');
  });

  it('renders the correct icon and status for info badge', () => {
    const statusInfo = { badge: 'info', status: 'In Progress' };
    renderComponent(statusInfo);

    const icon = screen.getByText('In Progress').parentElement.querySelector('.fa-info-circle');
    expect(icon).toBeInTheDocument();
    expect(screen.getByText('In Progress')).toBeInTheDocument();
    expect(screen.getByText('In Progress')).toHaveClass('feedback-tag-info');
  });
});