import $ from 'jquery'

$(document).on("turbolinks:load", function () {
  $('.checklist input').on('change', function(e){
    e.currentTarget.form.lastChild.click();
  })
});

$('.checklist').on('ajax:error', function(e, data, status, xhr){
  var error = $('.checklist .errors').addClass('alert alert-danger').removeClass('hidden')
  error.html('Ação inválida!').delay(3000).fadeOut(300)
})