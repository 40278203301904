import React from 'react';
import { render, screen, fireEvent } from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect';
import DocumentInput from './DocumentInput';

describe('DocumentInput component', () => {
  const props = {
    label: 'Upload Document',
    name: 'document',
    type: 'file',
    placeholder: 'Choose a document...',
    onChange: jest.fn(),
    required: true,
    errorMessage: '',
    onBlur: jest.fn(),
    disabled: false
  };

  test('should render component', () => {
    render(<DocumentInput {...props} />);
    expect(screen.getByText('Upload Document')).toBeInTheDocument();
    expect(screen.getByText('Escolher documento')).toBeInTheDocument();
  });

  test('should open file input on button click', () => {
    render(<DocumentInput {...props} />);
    const button = screen.getByText('Escolher documento');
    fireEvent.click(button);
    const input = screen.getByPlaceholderText('Choose a document...');
    expect(input).toBeInTheDocument();
  });

  test('should handle file selection', () => {
    render(<DocumentInput {...props} />);
    const file = new File(['dummy content'], 'example.txt', { type: 'text/plain' });
    const input = screen.getByPlaceholderText('Choose a document...');

    fireEvent.change(input, { target: { files: [file] } });
    expect(props.onChange).toHaveBeenCalledWith(expect.objectContaining({
      target: { name: 'document', value: file }
    }));
  });

  test('should remove selected file', () => {
    render(<DocumentInput {...props} />);
    const file = new File(['dummy content'], 'example.txt', { type: 'text/plain' });
    const input = screen.getByPlaceholderText('Choose a document...');

    fireEvent.change(input, { target: { files: [file] } });

    const removeButton = screen.getByRole('button', { name: '' });
    fireEvent.click(removeButton);

    expect(screen.queryByText('example.txt')).not.toBeInTheDocument();
    expect(input.value).toBe('');
  });

  test('should display error message', () => {
    render(<DocumentInput {...props} errorMessage="Error: Invalid file type" />);
    expect(screen.getByText('Error: Invalid file type')).toBeInTheDocument();
  });

  test('should disable input', () => {
    render(<DocumentInput {...props} disabled={true} />);
    const input = screen.getByPlaceholderText('Choose a document...');
    expect(input).toBeDisabled();
  });
});
