import React from 'react';
import { render, screen } from '@testing-library/react';
import '@testing-library/jest-dom/extend-expect';
import SubtitleWithDescription from './SubtitleWithDescription';

describe('SubtitleWithDescription component', () => {
  test('should render with subtitle and description', () => {
    render(<SubtitleWithDescription subtitle="My Subtitle" description="This is a description" />);
    expect(screen.getByText('My Subtitle')).toBeInTheDocument();
    expect(screen.getByText('This is a description')).toBeInTheDocument();
  });

  test('should render with only subtitle', () => {
    render(<SubtitleWithDescription subtitle="My Subtitle" />);
    expect(screen.getByText('My Subtitle')).toBeInTheDocument();
    expect(screen.queryByText('This is a description')).not.toBeInTheDocument();
  });

  test('should not render description if not provided', () => {
    render(<SubtitleWithDescription subtitle="My Subtitle" />);
    expect(screen.queryByText('This is a description')).not.toBeInTheDocument();
  });
});
