import React from "react";
import PropTypes from "prop-types";

const FeedbackSnackbar = ({ message, type, onClick }) => {
  const icons = {
    success: 'check-circle',
    error: 'exclamation-circle',
    warning: 'exclamation-triangle',
  }
  return (
    <div
      className={`alert alert-${type} relative text-dark py-3`}
    >
      <i
        className="fas fa-times absolute right-center clickable p-2"
        onClick={onClick}
        tabIndex="0"
        onKeyDown={e => e.key === 'Enter' && onClick()}
      />
      <i className={`fas fa-${icons[type]} mr-2`} />
      {message}
    </div>
  );
}

export default FeedbackSnackbar;

FeedbackSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
  onClick: PropTypes.func.isRequired
};