$(() => {

  const handleInstallmensButtonActions = (work_pact) => {
    $('.installments_quantity').on('click', function (event) {
      event.preventDefault();
      $('.installments-quantity-input').val($(this).data('value'));
    })
  }

  const setupvalueToggle = () => {
    const setVisual = (element, show, hide) => {
      $(`.${hide}-box`).hide()
      $(`.${hide}-box input`).val(null)
      $(`.${show}-box`).show()
      element.data('active', show)
    }

    const flipFunction = (element) => {
      const active = element.data('active')
      if (active == 'financed_amount') {
        next = 'installment_amount'
      } else {
        next = 'financed_amount'
      }
      setVisual(element, next, active)
    }

    const valueElement = $('#simulation-page-toggle-input-value')
    valueElement.on('change', (e) => {
      flipFunction($(e.target))
    })
    const toggleInputValue = document.querySelector('#simulation-page-toggle-input-value')

    if(valueElement.data('active') == 'financed_amount') {
      toggleInputValue.checked = false
    }
  }
  setupvalueToggle()
})
