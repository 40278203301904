import $ from 'jquery'

class ProposalFinancialDataFormCheckboxHandle {
    handle() {
        $("#proposal_new_account_to_debt_authorization").on('change', () => {
            if($("#proposal_new_account_to_debt_authorization").is(':checked')) {
                $('#proposal_debt_authorization_bank').prop('disabled', false)
                $('#proposal_debt_authorization_bank_branch').prop('disabled', false)
                $('#proposal_debt_authorization_bank_account_with_dv').prop('disabled', false)
                $('#proposal_debt_authorization_bank_account_type').prop('disabled', false)
            } else {
                $('#proposal_debt_authorization_bank').prop('disabled', true)
                $('#proposal_debt_authorization_bank_branch').prop('disabled', true)
                $('#proposal_debt_authorization_bank_account_with_dv').prop('disabled', true)
                $('#proposal_debt_authorization_bank_account_type').prop('disabled', true)
            }
        })

        document.addEventListener("DOMContentLoaded", () => {
            $('#proposal_authorize_debt_over_due_installments, #proposal_authorize_debt_over_credit_limit')
                .removeClass('is-valid')
            $('#proposal_debt_authorization_bank').prop('disabled', false)
            $('#proposal_debt_authorization_bank_branch').prop('disabled', false)
            $('#proposal_debt_authorization_bank_account_with_dv').prop('disabled', false)
            $('#proposal_debt_authorization_bank_account_type').prop('disabled', false)

            const bank = $('#proposal_bank').val()
            const bankAgency = $('#proposal_bank_agency').val()
            const bankAccountWithDv = $('#proposal_bank_account_with_dv').val()
            const bankAccountType = $('#proposal_bank_account_type').val()

            const debtAuthorizationBank = $('#proposal_debt_authorization_bank').val()
            const debtAuthorizationBankAgency = $('#proposal_debt_authorization_bank_branch').val()
            const debtAuthorizationBankAccountWithDv = $('#proposal_debt_authorization_bank_account_with_dv').val()
            const debtAuthorizationBankAccountType = $('#proposal_debt_authorization_bank_account_type').val()

            if (debtAuthorizationBank || debtAuthorizationBankAgency ||
                debtAuthorizationBankAccountWithDv || debtAuthorizationBankAccountType) {
                if (bank != debtAuthorizationBank) {
                    $('#proposal_new_account_to_debt_authorization').prop('checked', true)
                    return
                }
                if (bankAgency != debtAuthorizationBankAgency) {
                    $('#proposal_new_account_to_debt_authorization').prop('checked', true)
                    return
                }
                if (bankAccountWithDv != debtAuthorizationBankAccountWithDv) {
                    $('#proposal_new_account_to_debt_authorization').prop('checked', true)
                    return
                }
                if (bankAccountType != debtAuthorizationBankAccountType) {
                    $('#proposal_new_account_to_debt_authorization').prop('checked', true)
                    return
                }
            }

            $('#proposal_new_account_to_debt_authorization').prop('checked', false)
            $('#proposal_debt_authorization_bank').prop('disabled', true)
            $('#proposal_debt_authorization_bank_branch').prop('disabled', true)
            $('#proposal_debt_authorization_bank_account_with_dv').prop('disabled', true)
            $('#proposal_debt_authorization_bank_account_type').prop('disabled', true)
        })
    }
}

new ProposalFinancialDataFormCheckboxHandle().handle()
