inputs = $('input[id *= "refinance_simulation_form_proposal_number"][name != "refinance_simulation_form[with_insurance]"]')

if(inputs.length > 0 && inputs.first().attr('data-registration-number') != undefined) {
  $('input[type="checkbox"][name != "refinance_simulation_form[with_insurance]"]').change(function() {

    var registrationNumber = $(this).attr('data-registration-number');

    different_contracts = $('[data-registration-number != ' + registrationNumber + '][id *= "refinance_simulation_form_proposal_number"]')

    different_contracts.each(function() {
      $(this).prop('checked', false);
    });

    $('#registration-number-field').val(registrationNumber)
  });
}
