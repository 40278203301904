import React from "react";
import PropTypes from "prop-types";

const LinkButton = ({ link, text, size = "sm" }) => {

  const fonts = {
    sm: "font-10",
    md: "font-12",
    lg: "font-14"
  }
  const font = fonts[size];
  return (
    <a href={link} className={`btn btn-primary ${font}`}>
      {text}
    </a>
  )
}

export default LinkButton;

LinkButton.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.string
}