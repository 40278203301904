import React from 'react';
import { render, fireEvent, waitFor } from '@testing-library/react';
import MigrationPage from './MigrationPage';

jest.mock('./constructor', () => {
  const Yup = require('yup');
  return {
    handleFormSubmit: jest.fn(),
    initialValues: (_) => ({
      cpf: '',
      rate_table_id: '',
      net_amount: '',
      remaining_installments: '',
      installment_amount: '',
      installments_quantity: '',
      origin_bank_ispb: '',
    }),
    validationSchema: Yup.object().shape({
      cpf: Yup.string().required('Campo obrigatório'),
      rate_table_id: Yup.string().required('Campo obrigatório'),
      net_amount: Yup.number().required('Campo obrigatório'),
      remaining_installments: Yup.number().required('Campo obrigatório'),
      installment_amount: Yup.number().required('Campo obrigatório'),
      installments_quantity: Yup.number().required('Campo obrigatório'),
      origin_bank_ispb: Yup.string().required('Campo obrigatório'),
    })
  }
});

describe('MigrationPage', () => {
  const rateTables = [
    { id: '1' , label: 'Tabela 1' },
    { id: '2' , label: 'Tabela 2' },
  ];
  const banks = [
    ['Banco 1', '1'],
    ['Banco 2', '2'],
  ]

  const submitUrl = 'https://submit.url';
  const authToken = 'testAuthToken';

  it('should render form with initial values', () => {
    const { getByPlaceholderText } = render(
      <MigrationPage rateTables={rateTables} submitUrl={submitUrl} authToken={authToken} banks={banks} workPact='INSS'/>
    );

    expect(getByPlaceholderText('CPF')).toBeInTheDocument();
    expect(getByPlaceholderText('Saldo devedor')).toBeInTheDocument();
    expect(getByPlaceholderText('Nº de parcelas restantes')).toBeInTheDocument();
    expect(getByPlaceholderText('Valor da parcela')).toBeInTheDocument();
    expect(getByPlaceholderText('Nº original de parcelas')).toBeInTheDocument();
  });

  it('should validate form and show errors for empty fields', async () => {
    const { getByRole, getAllByText } = render(
      <MigrationPage rateTables={rateTables} submitUrl={submitUrl} authToken={authToken} banks={banks} workPact='INSS'/>
    );

    fireEvent.click(getByRole('button', { name: /simular/i }));

    await waitFor(() => {
      const errorMessages = getAllByText('Campo obrigatório');
      expect(errorMessages).toHaveLength(7)
    });
  });
});
