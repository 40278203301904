import React from "react";
import PropTypes from "prop-types";

const SummaryCard = ({ title, rows, values, actionIcon, actionFunction, alert }) => {
  return (
    <div
      className="d-flex flex-column bg-white mb-3 border shadow-sm rounded w-md relative"
    >
      <div className="px-2 py-1 d-flex align-items-center">
        <h5 className="py-1 font-16 mr-2">{title}</h5>
        {alert && (
          <div className="alert alert-warning px-2 py-1 m-0">
            {alert}
          </div>
        )}
      </div>
      {actionIcon && (
        <div
          className="absolute top-right"
        >
          <button
            className="btn btn-link text-primary"
            onClick={actionFunction}
          >
            {actionIcon}
          </button>
        </div>
      )}
      <table className="table-striped p-0 m-0">
        <tbody>
          {rows.map((row, index) => (
            <tr key={`row-${index}`}>
              <th className="px-2 py-1 font-weight-normal text-dark">{row}</th>
              <td className={values[index].style}>{values[index].value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SummaryCard;

SummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionIcon: PropTypes.element,
  actionFunction: PropTypes.func,
  alert: PropTypes.string,
};