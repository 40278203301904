import $ from 'jquery'

/**  Usage:
 *  Just anotate your element where the CPF will be inputed with
 *  the class `data-cep-origin`.
 *
 *  This element, must have an attribute called 'data-cep-fields'
 *  containing the ids of elements to be filled in the following order
 *  address_id, number_id*, complement_id, neigborhood_id, state_id, city_id
 *
 *  * number is not used by now
 *
 *  One example of suche declaration:
 *
 *  < input
 *    class="form-control cep-mask data-cep-origin"
 *    data-cep-fields="proposal_address_place,proposal_address_number,proposal_address_complement,proposal_address_neighborhood,proposal_address_state,proposal_address_city"
 *    type="text"
 *   >
 *
*/

// TODO: Test and Refactor ... waiting for jest setup
class CepDataFiller {
  scan() {
    $('.data-cep-origin').each((_, e) => {
      const [address_id, number_id, complement_id, neigborhood_id, state_id, city_id] = $(e).attr('data-cep-fields').split(',')

      $(e).on('keyup', (e, v) => {
        const cepValue = e.target.value.replace(/\D+/g,'')
        if(cepValue && cepValue.length == 8) {
          $('body, input').css({cursor: 'wait'})
          $.getJSON(`/api/cep/${cepValue}`)
            .then((data) => {
              $(`#${address_id}`).val(data['address_place'])
              $(`#${complement_id}`).val(data['address_complement'])
              $(`#${neigborhood_id}`).val(data['address_neighborhood'])
              $(`#${state_id}`).val(data['address_state'])
              $(`#${city_id}`).val(data['address_city'])
            }).always( () => {
              $('body, input').css({cursor: 'auto'})
            })
        }
      })
    })
  }
}

new CepDataFiller().scan()
