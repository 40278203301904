import React from "react";
import { render, screen } from "@testing-library/react";
import FeedbackSnackbar from "./FeedbackSnackbar";

describe("FeedbackSnackbar", () => {
  it("renders a feedback snackbar with a message and a type", () => {
    render(<FeedbackSnackbar message="Aviso: A oferta selecionada não é a mais vantajosa." type="warning" onClick={() => { }} />);
    expect(screen.getByText("Aviso: A oferta selecionada não é a mais vantajosa.")).toBeInTheDocument();
    expect(screen.getByText("Aviso: A oferta selecionada não é a mais vantajosa.")).toHaveClass("alert-warning");
  });
});