import React, { useRef } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import SubtitleWithDescription from "../SubtitleWithDescription";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { handleFormSubmit, initialValues, validationSchema } from "./constructor";

const MigrationPage = ({ rateTables, banks, submitUrl, authToken, workPact, offer }) => {
  const formRef = useRef(null);
  const formik = useFormik({
    initialValues: initialValues(offer),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values['work_pact'] = workPact
      values['with_unified_signature'] = true
      values['cpf'] = values.cpf.replace(/\D/g, '');
      handleFormSubmit(formRef, values, authToken);
    }
  });

  const proposalOptions = rateTables.map(rateTable => ({
    value: rateTable.id,
    label: rateTable.label
  }));

  const bankOptions = banks.map(company => ({
    value: company[1],
    label: company[0]
  }));

  const setSelectValue = (value, event) => {
    formik.setFieldValue(event.name, value.value);
  }

  return (
    <div>
      <div className="container">
        <form onSubmit={formik.handleSubmit} ref={formRef} action={submitUrl} method="post">
          <div className="card card-body card-body--small w-100">
            <div className="row">
              <div className="col-sm-4">
                <Input label="CPF da pessoa solicitante"
                  name="cpf"
                  type="text"
                  placeholder="CPF"
                  value={formik.values.cpf}
                  mask="cpf"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorMessage={formik.touched.cpf && formik.errors.cpf}
                  required
                />
              </div>
              <div className="col-sm-4">
                <Dropdown label="Taxa da proposta"
                  name="rate_table_id"
                  value={formik.values.rate_table_id}
                  onChange={setSelectValue}
                  options={proposalOptions}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.rate_table_id && formik.errors.rate_table_id}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card card-body card-body--small">
            <SubtitleWithDescription subtitle="Dados do contrato" />
            <div className="row">
              <div className="col-sm">
                <Input label="Saldo devedor"
                  name="net_amount"
                  mask="currency"
                  value={formik.values.net_amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.net_amount && formik.errors.net_amount}
                  placeholder="Saldo devedor"
                  required
                />
              </div>
              <div className="col-sm">
                <Input label="Nº de parcelas restantes"
                  name="remaining_installments"
                  type="number"
                  placeholder="Nº de parcelas restantes"
                  value={formik.values.remaining_installments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.remaining_installments && formik.errors.remaining_installments}
                  required
                />
              </div>
              <div className="col-sm">
                <Input label="Valor da parcela"
                  name="installment_amount"
                  type="number"
                  mask="currency"
                  value={formik.values.installment_amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.installment_amount && formik.errors.installment_amount}
                  placeholder="Valor da parcela"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <Dropdown
                  label="Instituição financeira"
                  name="origin_bank_ispb"
                  value={formik.values.origin_bank_ispb}
                  onChange={setSelectValue}
                  options={bankOptions}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.origin_bank_ispb && formik.errors.origin_bank_ispb}
                  required
                />
              </div>
              <div className="col-sm-4">
                <Input
                  label="Nº original de parcelas"
                  name="installments_quantity"
                  type="number"
                  value={formik.values.installments_quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.installments_quantity && formik.errors.installments_quantity}
                  placeholder="Nº original de parcelas"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">Simular</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MigrationPage;

MigrationPage.propTypes = {
  rateTables: PropTypes.array.isRequired,
  banks: PropTypes.array.isRequired,
  authToken: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  workPact: PropTypes.string.isRequired,
  offer: PropTypes.object
};