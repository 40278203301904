import Input from "./Input";
import React from "react";
import { fireEvent, render, screen } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

const onChange = jest.fn();

describe("Input", () => {
  beforeEach(() => {
    render(<Input label="Email" name="email" type="email" value="" placeholder="Enter your email" onChange={onChange} required />);
  });

  it("renders the label", () => {
    expect(screen.getByText("Email")).toBeInTheDocument();
  });

  it("renders the input field", () => {
    expect(screen.getByPlaceholderText("Enter your email")).toBeInTheDocument();
  });

  it("renders the input field with the correct type", () => {
    expect(screen.getByPlaceholderText("Enter your email")).toHaveAttribute("type", "email");
  });

  it("calls the onChange function when the input value changes", async () => {
    const input = screen.getByPlaceholderText("Enter your email");

    fireEvent.change(input, { target: { value: 'test@test.com' } });

    expect(onChange).toHaveBeenCalledTimes(1);
    await userEvent.type(input, "test@test.com");
    expect(onChange).toHaveBeenCalledTimes(1 + "test@test.com".length);
  });
});