export default class NumberHelper {
  static formatMoney(value) {
    if (isNaN(value)) return value;

    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  static formatPercentage(value, precision = 2) {
    if (isNaN(value)) return value;

    return `${(value*100).toFixed(precision)}%`;
  }
}
