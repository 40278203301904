import React, { useState } from "react";
import SummaryCard from "../SummaryCard";
import PropTypes from "prop-types";
import FeedbackSnackbar from "../FeedbackSnackbar";
import NumberHelper from "../../helpers/NumberHelper";

const MigrationSummary = ({
  charge,
  installmentQuantity,
  installmentAmount,
  amountDue,
  firstDueDate,
  lastDueDate,
  monthlyCustomerRate,
  annualCustomerRate,
  monthlyCetRate,
  annualCetRate,
  originBank,
  nextPageUrl,
  newPageUrl
}) => {
  const commonStyle = 'text-dark'
  const focusStyle = 'font-weight-bold ' + commonStyle
  const warningStyle = 'font-weight-bold text-danger'
  const invalidCharge = charge < 0;

  const rows = [
    "Troco previsto",
    "Parcelas",
    "Saldo devedor",
    "Vencimento",
    "Taxa de juros",
    "Custo Efetivo Total",
    "Intituição credora original"
  ];

  const onClick = () => {
    window.location.assign(nextPageUrl);
  }

  const onNewSimulation = () => {
    window.location.assign(newPageUrl);
  }

  const getWarningMessage = () => {
    if (invalidCharge) return "Altere para seguir";

    return null;
  }

  const warning = getWarningMessage();
  const [showWarning, setShowWarning] = useState(warning !== null)


  return (
    <div>
      {showWarning && <FeedbackSnackbar type="warning" message="O troco do contrato é negativo." onClick={() => setShowWarning(false)} />}
      <div
        className="d-flex flex-column p-2 card rounded-0"
      >
        <SummaryCard
          rows={rows}
          alert={warning}
          title='Contrato'
          values={[
            { value: NumberHelper.formatMoney(charge), style: invalidCharge ? warningStyle : focusStyle },
            { value: `${installmentQuantity}× de ${NumberHelper.formatMoney(installmentAmount)}`, style: focusStyle },
            { value: NumberHelper.formatMoney(amountDue), style: focusStyle },
            { value: `${firstDueDate} a ${lastDueDate}`, style: commonStyle },
            { value: `${NumberHelper.formatPercentage(monthlyCustomerRate)} a.m. | ${NumberHelper.formatPercentage(annualCustomerRate)} a.a.`, style: commonStyle },
            { value: `${NumberHelper.formatPercentage(monthlyCetRate)} a.m. | ${NumberHelper.formatPercentage(annualCetRate)} a.a.`, style: commonStyle },
            { value: originBank, style: focusStyle }
          ]}
        />
        <div className="d-flex flex-row align-self-end">

          <button
            onClick={onNewSimulation}
            className="btn btn-link mt-2"
          >
            Nova simulação
          </button>

          <button
            disabled={warning}
            onClick={onClick}
            className="btn btn-primary mt-2"
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
}

export default MigrationSummary;

MigrationSummary.propTypes = {
  charge: PropTypes.number.isRequired,
  installmentQuantity: PropTypes.number.isRequired,
  installmentAmount: PropTypes.number.isRequired,
  amountDue: PropTypes.number.isRequired,
  firstDueDate: PropTypes.string.isRequired,
  lastDueDate: PropTypes.string.isRequired,
  monthlyCustomerRate: PropTypes.number.isRequired,
  annualCustomerRate: PropTypes.number.isRequired,
  monthlyCetRate: PropTypes.number.isRequired,
  annualCetRate: PropTypes.number.isRequired,
  originBank: PropTypes.string.isRequired,
  nextPageUrl: PropTypes.string.isRequired,
  newPageUrl: PropTypes.string.isRequired
};
