import "jquery-mask-plugin";

$.fn.castValueToFloat = function () {
  this.val(function (_index, value) {
    return parseFloat(value).toFixed(2);
  });
  return this;
};

$.fn.convertToPercent = function (cases) {
  this.val(function (index, value) {
    const aux = value * 100;
    return Math.trunc(aux) + (value % 1).toFixed(2);
  });
  return this;
};

$(document).ready(() => {
  $(".currency-mask")
    .castValueToFloat()
    .mask("#.##0,00", { reverse: true, maxLength: false, placeholder: "0,00" });
  $(".cnpj-mask").mask("00.000.000/0000-00");
  $(".cpf-mask").mask("000.000.000-00");
  $(".phone-number-mask").mask("(00) 00000-0000");
  $(".sosal-phone-number-length-validation").on('keyup change', () => {
    let phoneNumber = $('.sosal-phone-number-length-validation').val();
    const correctLenghtOfMaskedPhoneNumber = 15;

    if(phoneNumber.length != correctLenghtOfMaskedPhoneNumber) {
      $('.sosal-phone-number-length-validation')
        .removeClass('is-valid')
        .addClass('is-invalid')
      $('.block-save-customer-data-sosal-button').addClass('blocking-button')
    } else {
      $('.sosal-phone-number-length-validation')
        .removeClass('is-invalid')
        .addClass('is-valid')
      $('.block-save-customer-data-sosal-button').removeClass('blocking-button')
    }
  });
  $(".date-mask").mask("00/00/0000", {
    placeholder: "DD/MM/YYYY",
    selectOnFocus: true,
  });
  $(".cep-mask").mask("00000-000");
  $(".percent-mask").convertToPercent().mask("990,000%", { reverse: true });
  $(".bank_account, .debt_authorization_bank_account_with_dv").mask("#-0", { reverse: true, maxlength: false });
  $(".bank_agency, .debt_authorization_bank_branch").mask("0000", { reverse: true, maxlength: true });
  $(".bank_account_with_letter").inputmask("9{1,20}-*", { numericInput: true, rightAlign: true, casing: "upper" });
  $(".benefit_number_mask").inputmask("9{1,10}", { numericInput: true, maxlength: true });
});
