import React from 'react';
import { render, screen } from '@testing-library/react';
import MigrationSummary from '.';
import NumberHelper from '../../helpers/NumberHelper';

jest.mock('../../helpers/NumberHelper', () => ({
  formatMoney: jest.fn((value) => `R$ ${value.toFixed(2)}`),
  formatPercentage: jest.fn((value) => `${(value * 100).toFixed(2)}%`)
}));

const props = {
  charge: -1.00,
  installmentQuantity: 84,
  installmentAmount: 0.00,
  amountDue: 0.00,
  firstDueDate: '00/00/0000',
  lastDueDate: '00/00/0000',
  monthlyCustomerRate: 0.015,
  annualCustomerRate: 0.145,
  monthlyCetRate: 0.002,
  annualCetRate: 0.010,
  originBank: 'Banco Original',
  nextPageUrl: '/offer_installments_financial_data',
  editPageUrl: '/offer_installments'
};

describe('MigrationSummary', () => {
  test('renders the component correctly', () => {
    render(<MigrationSummary {...props} />);

    expect(screen.getByText('Contrato')).toBeInTheDocument();
    expect(screen.getByText('Troco previsto')).toBeInTheDocument();
    expect(screen.getByText('Parcelas')).toBeInTheDocument();
    expect(screen.getByText('Saldo devedor')).toBeInTheDocument();
    expect(screen.getByText('Vencimento')).toBeInTheDocument();
    expect(screen.getByText('Taxa de juros')).toBeInTheDocument();
    expect(screen.getByText('Custo Efetivo Total')).toBeInTheDocument();
    expect(screen.getByText('Intituição credora original')).toBeInTheDocument();
  });

  test('displays a warning message if the charge is negative', () => {
    render(<MigrationSummary {...props} />);
    const warnings = screen.getAllByText('O troco do contrato é negativo.');
    expect(warnings.length).toBe(1);
  });

  test('formats the values correctly', () => {
    render(<MigrationSummary {...props} />);

    expect(NumberHelper.formatMoney).toHaveBeenCalledWith(props.charge);
    expect(NumberHelper.formatMoney).toHaveBeenCalledWith(props.installmentAmount);
    expect(NumberHelper.formatMoney).toHaveBeenCalledWith(props.amountDue);
    expect(NumberHelper.formatPercentage).toHaveBeenCalledWith(props.monthlyCustomerRate);
    expect(NumberHelper.formatPercentage).toHaveBeenCalledWith(props.annualCustomerRate);
    expect(NumberHelper.formatPercentage).toHaveBeenCalledWith(props.monthlyCetRate);
    expect(NumberHelper.formatPercentage).toHaveBeenCalledWith(props.annualCetRate);
  });

  test('button is disabled when there is a warning', () => {
    render(<MigrationSummary {...props} />);

    const button = screen.getByText('Continuar');
    expect(button).toBeDisabled();
  });

  test('button is enabled when there is no warning', () => {
    const validProps = {
      ...props,
      charge: 1.00
    };

    render(<MigrationSummary {...validProps} />);

    const button = screen.getByText('Continuar');
    expect(button).not.toBeDisabled();
  });

  test('renders all the values correctly', () => {
    render(<MigrationSummary {...props} />);

    expect(screen.getByText('R$ -1.00')).toHaveClass('font-weight-bold text-danger');
    expect(screen.getByText('84× de R$ 0.00')).toHaveClass('font-weight-bold text-dark');
    expect(screen.getByText('R$ 0.00')).toHaveClass('font-weight-bold text-dark');
    expect(screen.getByText('00/00/0000 a 00/00/0000')).toHaveClass('text-dark');
    expect(screen.getByText('1.50% a.m. | 14.50% a.a.')).toHaveClass('text-dark');
    expect(screen.getByText('0.20% a.m. | 1.00% a.a.')).toHaveClass('text-dark');
    expect(screen.getByText('Banco Original')).toHaveClass('font-weight-bold text-dark');
  });
});
