var moment = require('moment');

$('#proposal_birth_date').on('change', function(event) {
  var birthDate = event.target.value
  var age = moment().diff(moment(birthDate, 'dd/MM/YYYY'), 'years') + ' anos de idade.'

  $('.calculated-age > p').html(age)
});

$('#in100_request_birth_date').on('change', function(event) {
  var birthDate = event.target.value
  var age = moment().diff(moment(birthDate, 'dd/MM/YYYY'), 'years') + ' anos de idade.'

  $('.calculated-age > p').html(age)
});

