import React from "react";
import PropTypes from "prop-types";

const SubtitleWithDescription = ({ subtitle, description }) => {
  return (
    <div className="mb-1">
      <h2 className="font-16 mb-0 font-weight-bold text-dark">{subtitle}</h2>
      {description && <p className="text-dark">{description}</p>}
    </div>
  );
}

export default SubtitleWithDescription;

SubtitleWithDescription.propTypes = {
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string
};