import React from 'react';
import { render } from '@testing-library/react';
import MigrationUserInfoForm from './MigrationUserInfoForm';

describe('<MigrationUserInfoForm />', () => {
  beforeEach(() => {
    HTMLFormElement.prototype.submit = jest.fn();
  });

  afterEach(() => {
    jest.restoreAllMocks();
  });
  it('renders correctly', () => {
    const { getByText } = render(
      <MigrationUserInfoForm
        submitUrl="test-url"
        amountDue={5000}
        installmentAmount={500}
        cpf="123.456.789-09"
        authToken="auth-token"
        originBank={123}
        rgInstitutions={['SSP', 'DETRAN']}
        banks={[['Banco do Brasil', '001'], ['Caixa Econômica', '104']]}
        banksIspb={[['Banco do Brasil', '001'], ['Caixa Econômica', '104']]}
        states={{ SP: 'São Paulo', RJ: 'Rio de Janeiro' }}
        requiredDocuments={[{ name: 'doc1', label: 'Documento 1' }]}
      />
    );

    expect(getByText('Dados do benefício')).toBeInTheDocument();
    expect(getByText('Informações pessoais')).toBeInTheDocument();
    expect(getByText('Continuar')).toBeInTheDocument();
  });
});
