import SummaryCard from "./SummaryCard";
import React from "react";
import { render, screen } from "@testing-library/react";

const actionFunction = jest.fn();

describe("SummaryCard", () => {
  beforeEach(() => {
    render(
      <SummaryCard
        title="Oferta 1"
        rows={["Valor Financiado", "Valor da Parcela", "Quantidade de Parcelas"]}
        values={[{ value: "R$ 1000" }, { value: "R$ 100" }, { value: "10" }]}
        actionIcon={<i className="fas fa-edit"></i>}
        actionFunction={actionFunction}
      />
    );
  });

  it("renders the title", () => {
    expect(screen.getByText("Oferta 1")).toBeInTheDocument();
  });

  it("renders the rows", () => {
    expect(screen.getByText("Valor Financiado")).toBeInTheDocument();
    expect(screen.getByText("Valor da Parcela")).toBeInTheDocument();
    expect(screen.getByText("Quantidade de Parcelas")).toBeInTheDocument();
  });

  it("renders the values", () => {
    expect(screen.getByText("R$ 1000")).toBeInTheDocument();
    expect(screen.getByText("R$ 100")).toBeInTheDocument();
    expect(screen.getByText("10")).toBeInTheDocument();
  });
});