import React from "react";
import { render, screen, fireEvent } from "@testing-library/react";
import LoginPage from "./LoginPage"; // Assumindo que o caminho seja correto
import '@testing-library/jest-dom/extend-expect';

describe("LoginPage", () => {
  const defaultProps = {
    loginUrl: "/login",
    ssoUrl: "/sso",
    passwordNewUrl: "/password/new",
    passwordResetUrl: "/password/reset",
    passwordUnlockUrl: "/password/unlock",
    authToken: "test-token",
  };

  it("should render the login form", () => {
    render(<LoginPage {...defaultProps} />);

    expect(screen.getByText("Email")).toBeInTheDocument();
    expect(screen.getByText("Senha")).toBeInTheDocument();
    expect(screen.getByText("Entrar")).toBeInTheDocument();
    expect(screen.getByText("Entrar com SSO")).toBeInTheDocument();
    expect(screen.getByText("Primeiro acesso?")).toBeInTheDocument();
    expect(screen.getByText("Esqueceu sua senha?")).toBeInTheDocument();
    expect(screen.getByText("Não recebeu instruções de desbloqueio?")).toBeInTheDocument();
  });

  it("should call onSubmit when form is submitted", () => {
    const { container } = render(<LoginPage {...defaultProps} />);
    const form = container.querySelector("form");

    fireEvent.submit(form);

    expect(form).toHaveFormValues({
      email: "",
      password: "",
    });
  });

  it("should navigate to SSO when SSO button is clicked", () => {
    render(<LoginPage {...defaultProps} />);
    const ssoButton = screen.getByText("Entrar com SSO");

    const originalLocation = window.location;
    delete window.location;
    window.location = { href: "" };

    fireEvent.click(ssoButton);

    expect(window.location.href).toBe(defaultProps.ssoUrl);

    window.location = originalLocation;
  });
});
