require("../components/Loading");
require("../components/LoginPage");
require("../components/MigrationSummary");
require("../components/MigrationPage");
require("../components/LinkButton");
require("../components/CopyButton");
require("../components/ProposalStatusBadge");
require("../components/MigrationUserInfoForm");
require("./minton-init");
require("./masks");
require("./profile_avatar_change");
require("./select_installments");
require("./proposal_checklist");
require("./switchery");
require("./migration_filters");
require("./pages/simulation");
require("./pages/offer");
require("./pages/histories");
require("./pages/balance_request");
require("./pages/offer_installments_financial_data");
require("./pages/active_contracts");
require("CepDataFiller");
require("OperatorActions");
require("FormValidation");
require("ProposalFinancialDataFormCheckboxHandle")
require("./QuickFilter")
require("./DatePicker")

var componentRequireContext = require.context("../components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
