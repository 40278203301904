export const onFormSubmit = (event, formRef, authToken) => {
  event.preventDefault();

  const formData = new FormData(formRef.current);

  const email = formData.get("email");
  const password = formData.get("password");

  const authTokenInput = document.createElement("input");
  authTokenInput.type = "hidden";
  authTokenInput.name = "authenticity_token";
  authTokenInput.value = authToken;
  formRef.current.appendChild(authTokenInput);

  formRef.current.appendChild(createHiddenInput("user[email]", email));
  formRef.current.appendChild(createHiddenInput("user[password]", password));

  formRef.current.submit();
}


const createHiddenInput = (name, value) => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = name;
  input.value = value;
  return input;
};
