import React from "react";
import PropTypes from "prop-types";

const CopyButton = ({ text, link, size = "sm" }) => {
  const fonts = {
    sm: "font-10",
    md: "font-12",
    lg: "font-14"
  }
  const font = fonts[size];

  return (
    <button className={`btn btn-primary ${font}`} onClick={() => navigator.clipboard.writeText(link)}>
      {text}
    </button>
  )
}

export default CopyButton;

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  size: PropTypes.string
}
