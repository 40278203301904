import * as Yup from 'yup';
import BankValidator from '../../BankValidator';

export const initialValues = (cpf, originBank, params) => ({
  contract_number: params?.contract_number || '',
  customer_cpf: cpf,
  customer_name: params?.customer_name || '',
  customer_gender: params?.customer_gender || '',
  customer_phone: params?.customer_phone || '',
  customer_email: params?.customer_email || '',
  customer_birth_city: params?.customer_birth_city || '',
  customer_birth_state: params?.customer_birth_state || '',
  customer_mother_name: params?.customer_mother_name || '',
  customer_rg_number: params?.customer_rg_number || '',
  customer_rg_agency: params?.customer_rg_agency || '',
  customer_rg_state: params?.customer_rg_state || '',
  customer_rg_issuing_date: params?.customer_rg_issuing_date || '',
  address_zipcode: params?.address_zipcode || '',
  address_place: params?.address_place || '',
  address_number: params?.address_number || '',
  address_complement: params?.address_complement || '',
  address_neighborhood: params?.address_neighborhood || '',
  address_state: params?.address_state || '',
  address_city: params?.address_city || '',
  customer_politically_exposed: params?.customer_politically_exposed || false,
  origin_bank_ispb: originBank || '',
  bank: params?.bank || '',
  bank_agency: params?.bank_agency || '',
  bank_account_with_dv: params?.bank_account_with_dv || '',
  bank_account_type: params?.bank_account_type || '',
})

const requiredFieldMessage = 'Campo obrigatório';

const isNameValid = (value) => {
  const splittedName = value.split(' ').filter((nonSpaces) => nonSpaces !== '')
  return splittedName.length >= 2
}

export const validationSchema = Yup.object({
  contract_number: Yup.string().required(requiredFieldMessage),
  customer_name: Yup.string().required(requiredFieldMessage).test('customer_name', 'Digite nome e sobrenome', (value) => isNameValid(value)),
  customer_cpf: Yup.string().required(requiredFieldMessage),
  customer_phone: Yup.string().required(requiredFieldMessage),
  customer_email: Yup.string().email().required(requiredFieldMessage),
  customer_mother_name: Yup.string().required(requiredFieldMessage).test('customer_mother_name', 'Digite nome e sobrenome', (value) => isNameValid(value)),
  customer_rg_number: Yup.string().required(requiredFieldMessage),
  customer_rg_agency: Yup.string().required(requiredFieldMessage),
  customer_rg_state: Yup.string().required(requiredFieldMessage),
  customer_rg_issuing_date: Yup.string().required(requiredFieldMessage),
  customer_gender: Yup.string().required(requiredFieldMessage),
  customer_birth_city: Yup.string().required(requiredFieldMessage),
  customer_birth_state: Yup.string().required(requiredFieldMessage),
  customer_politically_exposed: Yup.boolean().required(requiredFieldMessage),
  address_zipcode: Yup.string().required(requiredFieldMessage),
  address_place: Yup.string().required(requiredFieldMessage),
  address_number: Yup.string().required(requiredFieldMessage),
  address_complement: Yup.string(),
  address_neighborhood: Yup.string().required(requiredFieldMessage),
  address_state: Yup.string().required(requiredFieldMessage),
  address_city: Yup.string().required(requiredFieldMessage),
  bank: Yup.string().required(requiredFieldMessage),
  bank_agency: Yup.string().required(requiredFieldMessage),
  bank_account_with_dv: Yup.string().required(requiredFieldMessage)
    .test('is-valid-account', 'Conta bancária inválida', function (value) {
      const { bank, bank_agency } = this.parent;
      return BankValidator.isAccountValid(bank, bank_agency, value);
    }),
  bank_account_type: Yup.string().required(requiredFieldMessage),
});

export const handleFormSubmit = (formRef, values, authToken) => {
  const form = formRef.current;

  values['work_pact'] = 'INSS';
  values['with_unified_signature'] = true;

  // format zipcode cpf and phone
  values['customer_cpf'] = values['customer_cpf'].replace(/\D/g, '');
  values['customer_phone'] = values['customer_phone'].replace(/\D/g, '');
  values['address_zipcode'] = values['address_zipcode'].replace(/\D/g, '');
  values['bank_account_with_dv'] = values['bank_account_with_dv'].replace(/\D/g, '');
  values['bank_agency'] = values['bank_agency'].replace(/\D/g, '');

  Object.keys(values).forEach(key => {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = `migration_contract[${key}]`;
    input.value = values[key];
    form.appendChild(input);
  });

  let tokenInput = document.createElement('input');
  tokenInput.type = 'hidden';
  tokenInput.name = 'authenticity_token';
  tokenInput.value = authToken;
  form.appendChild(tokenInput);

  form.submit();
};
