import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import SubtitleWithDescription from "../SubtitleWithDescription";
import Input from "../Input";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown";
import { handleFormSubmit, initialValues, validationSchema } from "./constructor";
import Checkbox from "../Checkbox";
import DocumentInput from "../DocumentInput";


const MigrationUserInfoForm = ({
  submitUrl,
  amountDue,
  installmentAmount,
  cpf,
  authToken,
  originBank,
  rgInstitutions,
  banks,
  states,
  requiredDocuments,
  banksIspb,
  params
}) => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const formik = useFormik({
    initialValues: initialValues(cpf, originBank, params),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      handleFormSubmit(formRef, values, authToken).then(() => {
        setIsLoading(false);
      });
    }
  });

  const bankOptions = banks.map(company => ({
    value: company[1],
    label: company[0]
  }));

  const bankIspbOptions = banksIspb.map(company => ({
    value: company[1],
    label: company[0]
  }));

  const rgOptions = rgInstitutions.map((institution) => {
    return { value: institution, label: institution };
  });

  const setSelectValue = (value, event) => {
    formik.setFieldValue(event.name, value.value);
  }

  const statesOptions = Object.keys(states).map((state) => {
    return { value: states[state], label: state };
  });

  const onZipCodeChange = async (e) => {
    const cep = e.target.value;
    formik.setFieldValue('address_zipcode', cep);
    const formattedCep = cep.replace(/\D/g, '');
    if (formattedCep.length !== 8) {
      return;
    }

    setLoadingAddress(true);
    const response = await fetch(`/api/cep/${formattedCep}`).catch(() => {
      setLoadingAddress(false);
      return;
    });
    const data = await response.json();

    formik.setFieldValue('address_place', data.address_place);
    formik.setFieldValue('address_neighborhood', data.address_neighborhood);
    formik.setFieldValue('address_city', data.address_city);
    formik.setFieldValue('address_state', data.address_state);
    setLoadingAddress(false);
  }

  return (
    <div className="container">
      <form onSubmit={formik.handleSubmit} ref={formRef} encType="multipart/form-data" action={submitUrl} method="post">
        <div className="card card-body card-body--small w-100">
          <SubtitleWithDescription subtitle="Dados do benefício" description="Os dados do benefício serão atualizados automaticamente após a assinatura." />
          <div className="row">
            <div className="col-6">
              <Input
                label="Tipo do benefício"
                name="benefit_kind"
                type="text"
                value={formik.values.benefit_kind}
                placeholder="Tipo do benefício"
                onChange={formik.handleChange}
                disabled
              />
            </div>
            <div className="col-6">
              <Input
                label="Número do benefício"
                name="benefit_number"
                type="text"
                value={formik.values.benefit_number}
                placeholder="Número do benefício"
                onChange={formik.handleChange}
                disabled
              />
            </div>
          </div>
          <SubtitleWithDescription subtitle="Dados do contrato" />
          <div className="row">
            <div className="col-sm">
              <Input
                label="Saldo devedor"
                name="amount_due"
                type="number"
                placeholder="Saldo devedor"
                mask="currency"
                value={amountDue}
                disabled
              />
            </div>
            <div className="col-sm">
              <Input
                label="Valor da parcela"
                name="installment_amount"
                type="number"
                mask="currency"
                placeholder="Valor da parcela"
                value={installmentAmount}
                disabled
              />
            </div>
            <div className="col-sm">
              <Dropdown
                options={bankIspbOptions}
                label="Banco de origem"
                name="origin_bank_ispb"
                type="text"
                value={originBank?.toString()?.padStart(3, '0')}
                disabled
              />
            </div>
            <div className="col-sm">
              <Input
                label="Número do contrato original"
                name="contract_number"
                placeholder="Número do contrato original"
                type="text"
                value={formik.values.contract_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={formik.touched.contract_number && formik.errors.contract_number}
                required
              />
            </div>
          </div>
        </div>

        <div className="card card-body card-body--small w-100 mt-4">
          <section>
            <SubtitleWithDescription subtitle="Informações pessoais" />
            <div className="row">
              <div className="col-sm">
                <Input
                  label="CPF"
                  name="customer_cpf"
                  type="text"
                  placeholder="CPF"
                  value={formik.values.customer_cpf}
                  error={formik.errors.customer_cpf}
                  mask={'cpf'}
                  disabled
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Nome completo"
                  name="customer_name"
                  type="text"
                  placeholder="Nome completo"
                  value={formik.values.customer_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_name && formik.errors.customer_name}
                  required
                />
              </div>
              <div className="col-sm">
                <Dropdown
                  options={[{ value: 'male', label: 'Masculino' }, { value: 'female', label: 'Feminino' }]}
                  label="Gênero"
                  name="customer_gender"
                  type="text"
                  value={formik.values.customer_gender}
                  onChange={setSelectValue}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_gender && formik.errors.customer_gender}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <Dropdown
                  label="Estado de Nascimento"
                  options={statesOptions}
                  name="customer_birth_state"
                  type="text"
                  value={formik.values.customer_birth_state}
                  onChange={setSelectValue}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_birth_state && formik.errors.customer_birth_state}
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Cidade de Nascimento"
                  name="customer_birth_city"
                  type="text"
                  placeholder="Cidade"
                  value={formik.values.customer_birth_city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_birth_city && formik.errors.customer_birth_city}
                  required
                />
              </div>
            </div>
            <Checkbox
              label="Tem pessoa exposta politicamente na família?"
              checked={formik.values.customer_politically_exposed}
              onChange={(value) => formik.setFieldValue('customer_politically_exposed', value)}
              name="customer_politically_exposed"
            />
          </section>
          <section>
            <SubtitleWithDescription subtitle="Contatos" description="As informações serão validadas em antifraude." />
            <div className="row">
              <div className="col-sm">
                <Input
                  label="Telefone"
                  name="customer_phone"
                  type="text"
                  value={formik.values.customer_phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  mask={'phone'}
                  errorMessage={formik.touched.customer_phone && formik.errors.customer_phone}
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="E-mail"
                  name="customer_email"
                  type="email"
                  value={formik.values.customer_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_email && formik.errors.customer_email}
                  required
                />
              </div>
            </div>
          </section>
          <section>
            <SubtitleWithDescription subtitle="Documentos" description="Informe os dados da carteira de identidade (RG)." />
            <div className="row">
              <div className="col-sm">
                <Input
                  label="Número do RG"
                  name="customer_rg_number"
                  type="text"
                  value={formik.values.customer_rg_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_rg_number && formik.errors.customer_rg_number}
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Nome da mãe"
                  name="customer_mother_name"
                  type="text"
                  value={formik.values.customer_mother_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_mother_name && formik.errors.customer_mother_name}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <Input
                  label="Data de expedição"
                  name="customer_rg_issuing_date"
                  type="date"
                  value={formik.values.customer_rg_issuing_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_rg_issuing_date && formik.errors.customer_rg_issuing_date}
                  required
                />
              </div>
              <div className="col-sm">
                <Dropdown
                  options={rgOptions}
                  label="Órgão expedidor"
                  name="customer_rg_agency"
                  type="text"
                  value={formik.values.customer_rg_agency}
                  onChange={setSelectValue}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_rg_agency && formik.errors.customer_rg_agency}
                  required
                />
              </div>
              <div className="col-sm">
                <Dropdown
                  options={statesOptions}
                  label="Estado de expedição"
                  name="customer_rg_state"
                  type="text"
                  value={formik.values.customer_rg_state}
                  onChange={setSelectValue}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.customer_rg_state && formik.errors.customer_rg_state}
                  required
                />
              </div>
            </div>
          </section>
          <section>
            <SubtitleWithDescription subtitle="Endereço" />
            <Input
              label="CEP"
              name="address_zipcode"
              type="text"
              mask="cep"
              disabled={loadingAddress}
              loading={loadingAddress}
              value={formik.values.address_zipcode}
              onChange={onZipCodeChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.touched.address_zipcode && formik.errors.address_zipcode}
              required
            />
            <div className="row">
              <div className="col-sm">
                <Input
                  label="Endereço"
                  name="address_place"
                  type="text"
                  disabled={loadingAddress}
                  loading={loadingAddress}
                  value={formik.values.address_place}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.address_place && formik.errors.address_place}
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Número"
                  name="address_number"
                  type="text"
                  value={formik.values.address_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.address_number && formik.errors.address_number}
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Complemento"
                  name="address_complement"
                  type="text"
                  value={formik.values.address_complement}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.address_complement && formik.errors.address_complement}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <Input
                  label="Bairro"
                  name="address_neighborhood"
                  type="text"
                  value={formik.values.address_neighborhood}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  loading={loadingAddress}
                  errorMessage={formik.touched.address_neighborhood && formik.errors.address_neighborhood}
                  required
                  disabled={!loadingAddress}
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Cidade"
                  name="address_city"
                  type="text"
                  value={formik.values.address_city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.address_city && formik.errors.address_city}
                  required
                  disabled={!loadingAddress}
                  loading={loadingAddress}
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Estado"
                  name="address_state"
                  type="text"
                  value={formik.values.address_state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.address_state && formik.errors.address_state}
                  required
                  disabled={!loadingAddress}
                  loading={loadingAddress}
                />
              </div>
            </div>
          </section>
          <section>
            <SubtitleWithDescription subtitle="Dados bancários" />
            <div className="row">
              <div className="col-sm">
                <Dropdown
                  label="Banco"
                  name="bank"
                  options={bankOptions}
                  type="text"
                  value={formik.values.bank}
                  onChange={setSelectValue}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.bank && formik.errors.bank}
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Agência"
                  name="bank_agency"
                  type="text"
                  value={formik.values.bank_agency}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.bank_agency && formik.errors.bank_agency}
                  required
                />
              </div>
              <div className="col-sm">
                <Input
                  label="Conta"
                  name="bank_account_with_dv"
                  type="text"
                  mask="branch"
                  value={formik.values.bank_account_with_dv}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.bank_account_with_dv && formik.errors.bank_account_with_dv}
                  required
                />
              </div>
              <div className="col-sm">
                <Dropdown
                  label="Tipo de conta"
                  name="bank_account_type"
                  options={[{ value: 'checking', label: 'Corrente' }, { value: 'savings', label: 'Poupança' }]}
                  value={formik.values.bank_account_type}
                  onChange={setSelectValue}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.bank_account_type && formik.errors.bank_account_type}
                  required
                />
              </div>
            </div>
          </section>
          <section>
            <SubtitleWithDescription subtitle="Comprovantes" />
            <div className="row">
              {
                requiredDocuments?.map((document) => {
                  return (
                    <div className="col-sm" key={document.name}>
                      <DocumentInput
                        label={document.label}
                        name={document.name}
                        type="file"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={formik.touched[document.name] && formik.errors[document.name]}
                      />
                    </div>
                  );
                })}
            </div>
          </section>
          <button type="submit" className="btn btn-primary mt-4 align-self-end" disabled={isLoading || !formik.isValid}>
            {isLoading ? 'Aguarde...' : 'Continuar'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default MigrationUserInfoForm;

MigrationUserInfoForm.propTypes = {
  submitUrl: PropTypes.string,
  amountDue: PropTypes.number,
  authToken: PropTypes.string,
  installmentAmount: PropTypes.number,
  cpf: PropTypes.string,
  originBank: PropTypes.string,
  rgInstitutions: PropTypes.array,
  banks: PropTypes.array,
  banksIspb: PropTypes.array,
  states: PropTypes.object,
  requiredDocuments: PropTypes.array,
  params: PropTypes.object
}