import $ from 'jquery'

$(document).on("turbolinks:load", function () {
  $('#btn-change-avatar').on('click', function() { 
    $('#user-avatar-input').click();
  });

  $('#user-avatar-input').on('change', show_preview_image);

  function show_preview_image(e) {
    var files = this.files;
    if (!files) return;
    var reader = new FileReader();
    reader.onload = function (event) {
      $('#user-avatar-img').attr("src", event.target.result);
      $('#warning-img-change').removeClass('is-hidden');
    }
    reader.readAsDataURL(files.item(0));
  }
});