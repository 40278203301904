import CopyButton from "./CopyButton";
import React from "react";
import { render, screen, fireEvent } from "@testing-library/react";

beforeAll(() => {
  Object.assign(navigator, {
    clipboard: {
      writeText: jest.fn(),
    },
  });
});

describe("CopyButton", () => {
  const text = "Copy me!";
  const link = "https://example.com";

  it("renders a button with the provided text", () => {
    render(<CopyButton text={text} />);
    expect(screen.getByText(text)).toBeInTheDocument();
  });

  it("copies the link to the clipboard when clicked", () => {
    render(<CopyButton text={text} link={link} />);
    fireEvent.click(screen.getByText(text));
    expect(navigator.clipboard.writeText).toHaveBeenCalledWith(link);
  });
});
