import React from "react";
import PropTypes from "prop-types";

const ProposalStatusBadge = ({ statusInfo }) => {
  const icons = {
    alert: "fa fa-exclamation-triangle",
    success: "fa fa-check-circle",
    danger: "fa fa-times-circle",
    info: "fa fa-info-circle"
  }

  return (
    <span className={`px-2 py-1 text-dark font-weight-bold feedback-tag-${statusInfo.badge}`}>
      <i className={icons[statusInfo.badge] + ' mr-1'}></i>
      {statusInfo.status}
    </span>
  )
}

export default ProposalStatusBadge;

ProposalStatusBadge.propTypes = {
  statusInfo: PropTypes.object.isRequired
}
