import { initialValues, validationSchema, handleFormSubmit } from './constructor';
import CpfHelper from '../../helpers/CpfHelper';

jest.mock('../../helpers/CpfHelper', () => ({
  isCPFValid: jest.fn(),
}));

describe('Form Constructor', () => {

  describe('Form Validation Schema', () => {
    test('should validate correctly with valid data', async () => {
      const validData = {
        cpf: '12345678909',
        rate_table_id: '1',
        net_amount: '1000',
        remaining_installments: '5',
        installment_amount: '200',
        installments_quantity: '5',
        origin_bank: 'Company',
      };

      CpfHelper.isCPFValid.mockReturnValue(true);

      await expect(validationSchema.validate(validData)).resolves.toBe(validData);
    });

    test('should invalidate with invalid CPF', async () => {
      const invalidData = {
        ...initialValues,
        cpf: 'invalidCPF',
        rate_table_id: '1',
        net_amount: '1000',
        remaining_installments: '5',
        installment_amount: '200',
        installments_quantity: '5',
        birth_date: '1990-01-01',
        origin_bank: 'Company',
      };

      CpfHelper.isCPFValid.mockReturnValue(false);

      await expect(validationSchema.validate(invalidData)).rejects.toThrow('CPF inválido');
    });

    test('should invalidate when required fields are missing', async () => {
      const invalidData = {
        ...initialValues,
      };

      await expect(validationSchema.validate(invalidData)).rejects.toThrow('Campo obrigatório');
    });
  });

  describe('handleFormSubmit', () => {
    let formRef;
    let values;
    let authToken;

    beforeEach(() => {
      formRef = {
        current: document.createElement('form'),
      };
      values = {
        cpf: '12345678909',
        rate_table_id: '1',
        net_amount: '1000',
        remaining_installments: '5',
        installment_amount: '200',
        installments_quantity: '5',
        origin_bank: 'Company',
      };
      authToken = 'testAuthToken';
    });

    test('should create hidden inputs and submit the form', () => {
      const hiddenInputs = 2;
      document.body.appendChild(formRef.current);

      formRef.current.submit = jest.fn();

      handleFormSubmit(formRef, values, authToken);

      const inputs = formRef.current.querySelectorAll('input[type="hidden"]');

      expect(inputs.length).toBe(Object.keys(values).length + hiddenInputs);

      Object.keys(values).forEach(key => {
        const input = formRef.current.querySelector(`input[name="amount_due_request_simulation_form[${key}]"]`);
        expect(input).not.toBeNull();
        expect(input.value).toBe(values[key]);
      });

      const tokenInput = formRef.current.querySelector('input[name="authenticity_token"]');
      expect(tokenInput).not.toBeNull();
      expect(tokenInput.value).toBe(authToken);

      handleFormSubmit(formRef, values, authToken);

      expect(formRef.current.submit).toHaveBeenCalled();
    });
  });

  describe('initialValues', () => {
    test('should return the correct initial values', () => {
      expect(initialValues({})).toEqual({
        cpf: '',
        rate_table_id: '',
        net_amount: '',
        remaining_installments: '',
        installment_amount: '',
        installments_quantity: 84,
        origin_bank: '',
      });
    });
    test('should return the correct initial values with offer', () => {
      const offer = {
        cpf: '12345678909',
        rate_table_id: '1',
        net_amount: '1000',
        remaining_installments: '5',
        installment_amount: '200',
        installments_quantity: '5',
        origin_bank: 'Company',
      };

      expect(initialValues(offer)).toEqual({...offer});
    });
  });
});
