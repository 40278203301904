import * as Yup from 'yup';
import CpfHelper from '../../helpers/CpfHelper';
import DateHelper from '../../helpers/DateHelper';

export const initialValues = (offer) => {
  return {
    cpf: offer?.cpf || '',
    rate_table_id: offer?.rate_table_id || '',
    net_amount: offer?.net_amount || '',
    remaining_installments: offer?.remaining_installments || '',
    installment_amount: offer?.installment_amount || '',
    installments_quantity: offer?.installments_quantity || 84,
    origin_bank_ispb: offer?.origin_bank_ispb?.toString()?.padStart(3, '0') || '',
  }
};

export const validationSchema = Yup.object({
  cpf: Yup.string().required('Campo obrigatório').test('cpf', 'CPF inválido', (value) => CpfHelper.isCPFValid(value)),
  rate_table_id: Yup.string().required('Campo obrigatório'),
  net_amount: Yup.string().required('Campo obrigatório'),
  remaining_installments: Yup.string().required('Campo obrigatório'),
  installment_amount: Yup.string().required('Campo obrigatório'),
  installments_quantity: Yup.string().required('Campo obrigatório'),
  origin_bank_ispb: Yup.string().required('Campo obrigatório'),
});


const createHiddenInput = (name, value) => {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = name;
  input.value = value;
  return input;
};

export const handleFormSubmit = (formRef, values, authToken) => {
  values.net_amount = values.net_amount.toString().replace(/[^\d.,]/g, '').replace(/\./g, '');
  values.installment_amount = values.installment_amount.toString().replace(/[^\d.,]/g, '').replace(/\./g, '');

  while (formRef.current.firstChild) {
    formRef.current.removeChild(formRef.current.firstChild);
  }

  Object.keys(values).forEach(key => {
    const input = createHiddenInput(`amount_due_request_simulation_form[${key}]`, values[key]);
    formRef.current.appendChild(input);
  });

  const hiddenTokenInput = createHiddenInput('authenticity_token', authToken);
  const withUnifiedSignatureInput = createHiddenInput('with_unified_signature', 'true');
  formRef.current.appendChild(hiddenTokenInput);
  formRef.current.appendChild(withUnifiedSignatureInput);
  formRef.current.submit();
}
