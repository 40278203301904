export default class BankValidator {
  static CODE_FOR = {
    bb: 1,
    santander: 33,
    brb: 70,
    caixa: 104,
    bradesco: 237,
    itau: 341
  }

  static isAccountValid(bank, agency, accountWithDv) {
    const account = accountWithDv.split("-")[0]
    const accountDV = accountWithDv.split("-")[1]
    return this.validatesAccount(bank, agency, account, accountDV)
  }

  static validatesAccount(bankCodeStrOrInt, branch, account, accountDV) {
    // it receives branch and account without DV
    const bankCode = parseInt(bankCodeStrOrInt)
    if (bankCode == this.CODE_FOR['bb'])
      return parseInt(accountDV) == this.computeBbDV(account)
    else if (bankCode == this.CODE_FOR['santander'])
      return parseInt(accountDV) == this.computeSantanderDV(branch, account)
    else if (bankCode == this.CODE_FOR['brb'])
      return parseInt(accountDV) == this.computeBrbDV(branch, account)

    else if (bankCode == this.CODE_FOR['caixa'])
      return (
        this.isNewCaixaAccountFormat(account) ||
        this.isCaixaExceptionAccount(account) ||
        this.possibleDvsForCaixa(branch, account).indexOf(parseInt(accountDV)) >= 0
      )

    else if (bankCode == this.CODE_FOR['bradesco'])
      return parseInt(accountDV) == this.computeBradescoDV(account)

    else if (bankCode == this.CODE_FOR['itau'])
      return parseInt(accountDV) == this.computeItauDV(branch, account)

    else
      return true
  }

  static isBranchValid(bankCodeStrOrInt, branch) {
    const bankCode = parseInt(bankCodeStrOrInt)
    if (bankCode == this.CODE_FOR['brb']) {
      return branch.length < 4 }
    else
      return true
  }

  static bankHasBranchDigit(bankCompensationCode) {
    // we only have DV computations for bb and bradesco
    return [this.CODE_FOR['bb'], this.CODE_FOR['bradesco']].indexOf(parseInt(bankCompensationCode)) >= 0
  }

  static computeBbDV(account) {
    const dv = this.internalProduct('98765432', account)
    return this.finalizeDV(11 - (dv % 11))
  }

  static computeSantanderDV(branch, account) {
    let dv = this.internalProduct('9731', branch, this.ignoreTen)
    dv += this.internalProduct('0097131973', account, this.ignoreTen)
    return this.finalizeDV(10 - this.ignoreTen(dv))
  }

  static computeBrbDV(branch, account) {
    if (branch.length > 3 || account.length > 6) {
      return null
    }

    branch = this.brbPaddingZero(branch, 3)
    account = this.brbPaddingZero(account, 6)

    const formatAccount = `${branch}${account}`
    const dv = this.internalProduct('298765432', formatAccount)
    let remainder = (parseInt(dv) % 11)
    let dvResult = 11 - remainder    
    return remainder < 2 ? 0 : dvResult
  }

  static possibleDvsForCaixa(branch, account) {
    /*
      Codes for account types:
        001: Conta Corrente de Pessoa Física
        002: Conta Simples de Pessoa Física
        003: Conta Corrente de Pessoa Jurídica
        006: Entidades Públicas
        007: Depósitos Instituições Financeiras
        013: Poupança de Pessoa Física
        022: Poupança de Pessoa Jurídica
        023: Conta Caixa Fácil
        028: Poupança de Crédito Imobiliário
        032: Conta Investimento Pessoa Física
        034: Conta Investimento Pessoa Jurídica
        037: Conta Salário
        043: Depósitos Lotéricos
        131: Poupança Integrada
        >> We only accept 001, 002, 013, 023, 037, 131

      Algorithm:
        0474 00100000448  0 4 7 4  0 0 1              8 5 4 1  D
        8765 43298765432  8 7 6 5  4 3 2      9 8 7 6 5 4 3 2
                          | | | |  | | |      | | | | | | | |______:  2
                          | | | |  | | |      | | | | | | |________: 12
                          | | | |  | | |      | | | | | |__________: 20
                          | | | |  | | |      | | | | |____________: 40
                          | | | |  | | |      | | | |______________:  0
                          | | | |  | | |      | | |________________:  0
                          | | | |  | | |      | |__________________:  0
                          | | | |  | | |      |____________________:  0
                          | | | |  | | |___________________________:  2
                          | | | |  | |_____________________________:  0
                          | | | |  |_______________________________:  0
                          | | | |__________________________________: 20
                          | | |____________________________________: 42
                          | |______________________________________: 28
                          |________________________________________:  0
                                                                    ----
                                                                    166
        (166 * 10) % 11 => 0
    */
    let dv = this.internalProduct('8765', branch)
    dv += this.internalProduct('43298765432', account)
    if (account.length <= 8) {
      // if user did NOT include accountType on account number
      const possibleAccountTypes = ['001', '002', '013', '023', '037', '131']
      return possibleAccountTypes.map((accountType) => {
        const increment = this.internalProduct('432', accountType)
        return this.finalizeDV(((dv + increment) * 10) % 11)
      })
    } else {
      // user included accountType on account number
      return [this.finalizeDV((dv * 10) % 11)]
    }
  }

  static isNewCaixaAccountFormat(account) {
    // There's a new format for caixa accounts, but there's no available validation info.
    // The only usable rule is the length of the account, 9 digits.
    return account.length == 9
  }

  static isCaixaExceptionAccount(account) {
    return account.startsWith('8') || account.startsWith('9')
  }

  static computeBradescoDV(account) {
    const dv = this.internalProduct('765432765432', account)
    return this.finalizeDV(11 - (dv % 11))
  }

  static computeItauDV(branch, account) {
    let dv = this.internalProduct('2121', branch, this.sumDigits)
    dv += this.internalProduct('2121212', account, this.sumDigits)
    return this.finalizeDV(10 - (dv % 10))
  }

  static internalProduct(weightsStr, numberStr, processPartialResult) {
    let [result, i] = Array(2).fill(0)
    const reversedWeights = weightsStr.split('').reverse()
    numberStr.split('').reverse().forEach((digitStr) => {
      const partialResult = parseInt(digitStr) * parseInt(reversedWeights[i++])
      result += processPartialResult ? processPartialResult.call(this, partialResult) : partialResult
    })
    return result
  }

  static sumDigits(number) {
    let result = 0
    number.toString().split('').forEach((digitStr) => {
      result += parseInt(digitStr)
    })
    return result
  }

  static ignoreTen(number) {
    const numberStr = number.toString()
    return parseInt(numberStr[numberStr.length - 1])
  }

  static finalizeDV(dv) {
    return dv >= 10 ? 0 : dv
  }

  static brbPaddingZero(number, quantity) {
    while (number.length < quantity) {
      number = `0${number}`
    }

    return number
  }
}
