import React, { Component } from "react";
import PropTypes from "prop-types";
import ActionCable from "actioncable";

const JOB_STATUS = {
  starting: {
    title: "Iniciando execução",
    msg: "Sua solicitação será executada em breve, aguarde um momento."
  },
  running: {
    title: "Processando solicitação",
    msg: "Por favor, aguarde mais um pouco."
  },
  finished: {
    title: "Oba! Solicitação processada com sucesso",
    msg: "Clique em 'avançar' para ser redirecionado."
  },
  error: {
    title: "Erro durante a execução",
    msg: "Tente novamente ou entre em contato com o suporte."
  }
};

export default class Loading extends Component {
  static propTypes = {
    success_url: PropTypes.string,
    error_url: PropTypes.string,
    channel: PropTypes.string,
    channel_id: PropTypes.string,
    channel_name: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      jobStatus: '',
      jobResult: JOB_STATUS.starting
    }

    ActionCable.createConsumer().subscriptions.create(
      { channel: props.channel, channel_id: props.channel_id },
      { received: (data) => this.handleJobResult(data) }
    );
  }

  handleJobResult = (result) => {
    if(!result?.status) return;

    this.setState({
      jobStatus: result.status,
      jobResult: JOB_STATUS[result.status],
      jobMessage: result.message,
      jobUrlParam: result.url_param,
      jobQueryParams: result.query_params
    });
  };

  setQueryParams = (currentUrl, queryParams) => {
    const searchParams = new URLSearchParams(queryParams).toString()

    return `${currentUrl}?${searchParams}`
  }

  getSuccessUrl = () => {
    let sucess_url = this.state.jobUrlParam?.length 
      ? `${this.props.success_url}/${this.state.jobUrlParam}` : this.props.success_url

    if(this.state.jobQueryParams != null) {
      sucess_url = this.setQueryParams(sucess_url, this.state.jobQueryParams)
    }

    return sucess_url
  }

  renderSuccess(){
    return (
      <div className="loading-success">
        <div className="loading-report-success">
          <div className="loading-report-success-text">
            {this.state.jobMessage}
          </div>
        </div>
        <div className="loading-success-button">
          <a href={this.getSuccessUrl()} className="btn btn-primary">Avançar</a>
        </div>
      </div>
    );
  }

  renderError(){
    return (
      <div className="loading-error">
        <div>
          Para reportar o problema, copie a mensagem abaixo e envie para o responsável:
        </div>
        <div className="loading-report-error">
          <div className="loading-report-error-text">
            Durante a execução do {this.props.channel_name || this.props.channel}{'\n'}{'\n'}
            Ocorreu o erro:{'\n'}
            {this.state.jobMessage.replace(/\\n/g, '\n') }
          </div>
        </div>
        <div className="loading-error-button">
          <a href={this.props.error_url} className="btn btn-primary">Voltar</a>
        </div>
      </div>
    );
  }

  renderLoading() {
    return(
      <div>
        <div className="loader"></div>
      </div>
    )
  }

  render() {
    const { jobResult, jobStatus } = this.state;

    return (
      <div>
        <div className="loading-title">
          <h4>{jobResult.title}</h4>
        </div>
        <div className="msg">
          {jobResult.msg}
        </div>
        { jobStatus == 'error' ? this.renderError() : '' }
        { jobStatus == 'finished' ? this.renderSuccess() : '' }
        { !['error', 'finished'].includes(jobStatus) ? this.renderLoading() : '' }
      </div>
    );
  }
}
