import React from "react";
import { render, screen } from "@testing-library/react";
import LinkButton from "./LinkButton";

describe("LinkButton component", () => {
  test("renders correctly with given props", () => {
    const link = "https://example.com";
    const text = "Click here";

    render(<LinkButton link={link} text={text} />);

    const linkElement = screen.getByRole('link', { name: text });
    expect(linkElement).toBeInTheDocument();
    expect(linkElement).toHaveAttribute('href', link);
    expect(linkElement).toHaveTextContent(text);
  });
});
