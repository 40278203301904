import React from "react";
import PropTypes from "prop-types";

const Button = ({ text, onClick, type, variant, className }) => {
  const variants = {
    primary: "bg-primary text-white",
    secondary: "bg-secondary text-primary",
  };
  return (
    <button onClick={onClick} type={type} className={`font-weight-bold py-2 no-border no-shadow rounded ${variants[variant]} ${className}`}>
      {text}
    </button>
  );
}

export default Button;

Button.propTypes = {
  text: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};