import $ from 'jquery'
import 'bootstrap-datepicker'

$(document).on("turbolinks:load", function () {
  $('#search_start_date').datepicker({
    autoclose: true,
    format: 'dd/mm/yyyy',
    language: 'pt-BR'
  });

  $('#search_end_date').datepicker({
    autoclose: true,
    format: 'dd/mm/yyyy',
    language: 'pt-BR'
  });
});