export default class CpfHelper {

  static _isCpfDigitValid(cpfToTest, digitIndex) {
    let sum = 0;
    const weight = digitIndex + 2
    const digitsToAnalyze = (digitIndex + 1)

    for (let i = 1; i < digitsToAnalyze; i++) {
      sum += parseInt(cpfToTest.substring(i - 1, i)) * (weight - i);
    }

    let rem = (sum * 10) % 11
    if (rem > 9) {
      rem = 0;
    }

    const digit = cpfToTest.substring(digitIndex, digitIndex + 1)
    return rem == parseInt(digit)
  }

  static _isCpfEqualDigitsValid(cpfToTest) {
    const failureCases = [
      "00000000000", "11111111111", "22222222222", "33333333333", "44444444444", "55555555555",
      "66666666666", "77777777777", "88888888888", "99999999999"
    ]

    return cpfToTest.length == 11 && !failureCases.includes(cpfToTest)
  }

  static isCPFValid(cpf) {
    let formatedCpf = cpf.replace(/\D/g, '');
    return this._isCpfEqualDigitsValid(formatedCpf) && this._isCpfDigitValid(formatedCpf, 9) && this._isCpfDigitValid(formatedCpf, 10)
  }
}