import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select'

const Dropdown = ({ label, name, value, options, onChange, required, errorMessage, disabled, onBlur }) => {
  return (
    <div className="form-group text-dark">
      <label htmlFor={name} className="mb-1 text-dark">{label}</label>
      <Select
        id={name}
        name={name}
        value={options.find(option => option.value === value)}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={label}
        isSearchable
        required={required}
        aria-label={label}
        isDisabled={disabled}
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor: errorMessage ? '#dc3545' : '#898989',
            color: '#898989',
            padding: '0.35rem 0.75rem',
          }),
        }}
        components={{
          IndicatorSeparator: () => null
        }}
      />
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
}

export default Dropdown;

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
};