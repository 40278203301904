$(document).on("turbolinks:load", function () {
  $('.installment-collapse')
    .on('shown.bs.collapse', function () {
      $(this).parent()
        .find(".remixicon-add-line")
        .removeClass("remixicon-add-line")
        .addClass("remixicon-subtract-line");
    })
    .on('hidden.bs.collapse', function () {
      $(this).parent()
        .find(".remixicon-subtract-line")
        .removeClass("remixicon-subtract-line")
        .addClass("remixicon-add-line");
    });
});
