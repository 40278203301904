import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from 'react-currency-input';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

const Input = ({
  label,
  name,
  type,
  mask,
  value,
  placeholder,
  onChange,
  required,
  errorMessage,
  onBlur,
  disabled,
  loading
}) => {
  const borderClass = classNames('form-control', {
    'border-danger': errorMessage
  });

  const formatBranchValue = (val) => {
    const numbers = val.replace(/\D/g, '');
    if (numbers.length > 1) {
      return `${numbers.slice(0, -1)}-${numbers.slice(-1)}`;
    }
    return numbers;
  };

  const handleBranchChange = (event) => {
    const formattedValue = formatBranchValue(event.target.value);
    onChange({ target: { name: event.target.name, value: formattedValue } });
  };

  const masks = {
    phone: '(99) 99999-9999',
    cpf: '999.999.999-99',
    account: 'account',
    cep: '99.999-999',
    branch: '',
    currency: 'currency',
    number: 'number',
    default: '',
  };

  const maskProps = mask === 'branch' ? {
    mask: '',
    value: formatBranchValue(value),
    onChange: handleBranchChange
  } : {
    mask: masks[mask] || masks.default,
    value: value,
    onChange: onChange
  };

  return (
    <div className="form-group position-relative">
      <label htmlFor={name} className="mb-1 text-dark">{label}</label>
      {mask === 'currency' ? (
        <CurrencyInput
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          onChangeEvent={onChange}
          decimalSeparator=","
          thousandSeparator="."
          prefix="R$ "
          precision={2}
          disabled={disabled}
          onBlur={onBlur}
          required={required}
          className={borderClass}
        />
      ) : (
        <InputMask
          id={name}
          type={type}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          className={borderClass}
          {...maskProps}
        />
      )}
      {loading && (
        <i className="fas fa-spinner fa-spin loading-icon"></i>
      )}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  value: '',
  placeholder: '',
  required: false,
  errorMessage: '',
  onBlur: () => { },
  mask: '',
  disabled: false,
  loading: false
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  mask: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default Input;
