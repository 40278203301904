import $ from 'jquery'
class QuickFilter {
    initialize() {
        $('#quick_filter_work_pact').on('change', function (e) {
            e.preventDefault();
            const cur_value = $(this).val()

            const search = location.search.replace('?', '').split('&')
            const newhref = location.href.replace(location.search, '')

            let not_workpacts = search.filter((item) => item.match(/^(?!work_pact).*/))

            if (cur_value !== 'none') {
                not_workpacts.push(`work_pact=${cur_value}`)
            }

            location.href = newhref + '?' + not_workpacts.filter(n => n).join('&')
        })
    }
}

new QuickFilter().initialize()
