import $ from 'jquery'
import 'bootstrap-daterangepicker'

class DatePicker {
    initialize() {
        document.addEventListener('turbolinks:load', function () {
            $('#period').daterangepicker({
                "maxDate": new Date(),
                "locale": {
                    "format": "DD/MM/YYYY",
                    "separator": " - ",
                    "applyLabel": "Confirmar",
                    "cancelLabel": "Cancelar",
                    "fromLabel": "De",
                    "toLabel": "Até",
                    "customRangeLabel": "Custom",
                    "weekLabel": "S",
                    "daysOfWeek": [
                        "Dom",
                        "Seg",
                        "Ter",
                        "Qua",
                        "Qui",
                        "Sex",
                        "Sab"
                    ],
                    "monthNames": [
                        "Janeiro",
                        "Fevereiro",
                        "Março",
                        "Abril",
                        "Maio",
                        "Junho",
                        "Julho",
                        "Agosto",
                        "Setembro",
                        "Outubro",
                        "Novembro",
                        "Dezembro"
                    ],
                    "firstDay": 1
                },
                "drops": "auto"
            }
            );
        });
    }
}
$(document).ready(function() {
    $('.date-mask').attr('placeholder', 'DD/MM/AAAA');
});

new DatePicker().initialize()
