import React from 'react';
import { render, screen } from '@testing-library/react';
import Dropdown from './Dropdown';
import selectEvent from 'react-select-event';

describe('Dropdown Component', () => {
  const mockOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
  ];
  const mockOnChange = jest.fn();
  const mockOnBlur = jest.fn();

  test('renders correctly with all props', () => {
    render(
      <Dropdown
        label="Test Label"
        name="testName"
        value="option1"
        options={mockOptions}
        onChange={mockOnChange}
        onBlur={mockOnBlur}
        required
        errorMessage="Error message"
      />
    );

    expect(screen.getByLabelText('Test Label')).toBeInTheDocument();
    expect(screen.getByText('Error message')).toBeInTheDocument();
  });

  test('calls onChange function when option is selected', async () => {
    render(
      <Dropdown
        label="Test Label"
        name="testName"
        value=""
        options={mockOptions}
        onChange={mockOnChange}
        onBlur={mockOnBlur}
      />
    );

    const selectInput = screen.getByLabelText('Test Label');
    await selectEvent.select(selectInput, 'Option 2');

    expect(mockOnChange).toHaveBeenCalledTimes(1);
  });

  test('shows error message when provided', () => {
    render(
      <Dropdown
        label="Test Label"
        name="testName"
        value="option1"
        options={mockOptions}
        onChange={mockOnChange}
        errorMessage="Error message"
      />
    );

    expect(screen.getByText('Error message')).toBeInTheDocument();
  });

  test('does not show error message when not provided', () => {
    render(
      <Dropdown
        label="Test Label"
        name="testName"
        value="option1"
        options={mockOptions}
        onChange={mockOnChange}
      />
    );

    expect(screen.queryByText('Error message')).not.toBeInTheDocument();
  });

  test('renders the correct option based on value prop', () => {
    render(
      <Dropdown
        label="Test Label"
        name="testName"
        value="option2"
        options={mockOptions}
        onChange={mockOnChange}
      />
    );

    expect(screen.getByText('Option 2')).toBeInTheDocument();
  });
});
