import { initialValues, validationSchema, handleFormSubmit } from './constructor';
import { createRef } from 'react';

test('initialValues should return the correct initial values', () => {
  const values = initialValues('');
  expect(values).toEqual({
    contract_number: '',
    customer_cpf: '',
    customer_name: '',
    customer_gender: '',
    customer_phone: '',
    customer_email: '',
    customer_birth_city: '',
    customer_birth_state: '',
    customer_mother_name: '',
    customer_rg_number: '',
    customer_rg_agency: '',
    customer_rg_state: '',
    customer_rg_issuing_date: '',
    address_zipcode: '',
    address_place: '',
    address_number: '',
    address_complement: '',
    address_neighborhood: '',
    address_state: '',
    address_city: '',
    customer_politically_exposed: false,
    bank: '',
    bank_agency: '',
    bank_account_with_dv: '',
    bank_account_type: '',
  });
});

test('validationSchema should validate the correct fields', async () => {
  const validData = {
    benefit_kind: 'some kind',
    benefit_number: '12345',
    amount_due: '1000',
    installment_amount: '100',
    origin_bank_ispb: 'some bank',
    contract_number: '98765',
    customer_cpf: '123.456.789-00',
    customer_name: 'John Doe',
    customer_gender: 'M',
    customer_phone: '(11) 99999-9999',
    customer_email: 'john.doe@example.com',
    customer_birth_city: 'São Paulo',
    customer_birth_state: 'SP',
    customer_mother_name: 'Jane Doe',
    customer_rg_number: '123456789',
    customer_rg_agency: 'SSP',
    customer_rg_state: 'SP',
    customer_rg_issuing_date: '2020-01-01',
    address_zipcode: '12345-678',
    address_place: 'Rua Exemplo',
    address_number: '123',
    address_complement: 'Apto 1',
    address_neighborhood: 'Bairro Exemplo',
    address_state: 'SP',
    address_city: 'São Paulo',
    customer_politically_exposed: false,
    bank: 'Banco Exemplo',
    bank_agency: '1234',
    bank_account_with_dv: '12345',
    bank_account_type: 'Conta Corrente',
  };

  await expect(validationSchema.isValid(validData)).resolves.toBe(true);

  const invalidData = {
    benefit_kind: '',
    benefit_number: '',
    amount_due: '',
    installment_amount: '',
    origin_bank_ispb: '',
    contract_number: '',
    customer_cpf: '',
    customer_name: '',
    customer_gender: '',
    customer_phone: '',
    customer_email: 'invalid-email',
    customer_birth_city: '',
    customer_birth_state: '',
    customer_mother_name: '',
    customer_rg_number: '',
    customer_rg_agency: '',
    customer_rg_state: '',
    customer_rg_issuing_date: '',
    address_zipcode: '',
    address_place: '',
    address_number: '',
    address_complement: '',
    address_neighborhood: '',
    address_state: '',
    address_city: '',
    customer_politically_exposed: false,
  };

  await expect(validationSchema.isValid(invalidData)).resolves.toBe(false);
});

test('handleFormSubmit should create hidden inputs and submit the form', () => {
  const formRef = createRef();
  formRef.current = document.createElement('form');
  formRef.current.submit = jest.fn();

  const values = {
    amount_due: '1000',
    benefit_kind: 'some kind',
    contract_number: '12345',
    customer_cpf: '123.456.789-00',
    customer_phone: '(11) 99999-9999',
    address_zipcode: '12345-678',
    bank_agency: '1234',
    bank_account_with_dv: '12345',
  };

  const authToken = 'some-token';

  document.body.appendChild(formRef.current);

  handleFormSubmit(formRef, values, authToken);

  expect(formRef.current.querySelector('input[name="migration_contract[amount_due]"]').value).toBe('1000');
  expect(formRef.current.querySelector('input[name="migration_contract[benefit_kind]"]').value).toBe('some kind');
  expect(formRef.current.querySelector('input[name="migration_contract[contract_number]"]').value).toBe('12345');
  expect(formRef.current.querySelector('input[name="migration_contract[work_pact]"]').value).toBe('INSS');
  expect(formRef.current.querySelector('input[name="migration_contract[with_unified_signature]"]').value).toBe('true');
  expect(formRef.current.querySelector('input[name="authenticity_token"]').value).toBe(authToken);

  expect(formRef.current.submit).toHaveBeenCalled();

  document.body.removeChild(formRef.current);
});
