import $ from 'jquery'
import BankValidator from 'BankValidator'

class FormValidation {
    valid() {
        $('#proposal_rg_number').on('keyup change', () => {
            const rg = $('#proposal_rg_number').val();
            const regex = /^[a-zA-Z0-9]+$/

            if(regex.test(rg)) {
                $('#proposal_rg_number')
                    .removeClass('is-invalid')
                    .addClass('is-valid')
            } else {
                $('#proposal_rg_number')
                    .removeClass('is-valid')
                    .addClass('is-invalid')
            }
        });

        $('#proposal_bank_agency').on('keyup change', (e, v) => {
            const bank = $('#proposal_bank').val();
            const agency = $('#proposal_bank_agency').val();

            if ((agency.trim().length != 0) &&
                (BankValidator.isBranchValid(bank, agency)))
            {
                $('.bank_agency_invalid').remove()

                $('#proposal_bank_agency')
                    .removeClass('is-invalid')
                    .addClass('is-valid')

            } else {
                $('.bank_agency_invalid').remove()
                $('.proposal_bank_agency')
                    .append('<div class="bank_agency_invalid invalid-feedback">Dados Inválidos</div>')

                $('#proposal_bank_agency')
                    .removeClass('is-valid')
                    .addClass('is-invalid')

            }
        })

        $('#proposal_debt_authorization_bank_branch').on('keyup change', (e, v) => {
            const bank = $('#proposal_debt_authorization_bank').val();
            const agency = $('#proposal_debt_authorization_bank_branch').val();

            if ((agency.trim().length != 0) &&
                (BankValidator.isBranchValid(bank, agency)))
            {
                $('.debt_authorization_bank_branch_invalid').remove()

                $('#proposal_debt_authorization_bank_branch')
                    .removeClass('is-invalid')
                    .addClass('is-valid')

            } else {
                $('.debt_authorization_bank_branch_invalid').remove()
                $('.proposal_debt_authorization_bank_branch')
                    .append('<div class="debt_authorization_bank_branch_invalid invalid-feedback">Dados Inválidos</div>')

                $('#proposal_debt_authorization_bank_branch')
                    .removeClass('is-valid')
                    .addClass('is-invalid')

            }
        })

        $('#proposal_bank_account_with_dv').on('keyup change', (e, v) => {
            const bank = $('#proposal_bank').val();
            const agency = $('#proposal_bank_agency').val();
            const accountWithDv = $('#proposal_bank_account_with_dv').val();

            if ((accountWithDv.trim().length > 3 ) &&
                (BankValidator.isAccountValid(bank, agency, accountWithDv))){

                $('.bank_account_invalid').remove()

                $('#proposal_bank_account_with_dv')
                    .removeClass('is-invalid')
                    .addClass('is-valid')

            } else {
                $('.bank_account_invalid').remove()
                $('.proposal_bank_account_with_dv')
                    .append('<div class="bank_account_invalid invalid-feedback">Dados Inválidos</div>')

                $('#proposal_bank_account_with_dv')
                    .removeClass('is-valid')
                    .addClass('is-invalid')
            }
        })

        $('#proposal_debt_authorization_bank_account_with_dv').on('keyup change', (e, v) => {
            const bank = $('#proposal_debt_authorization_bank').val();
            const agency = $('#proposal_debt_authorization_bank_branch').val();
            const accountWithDv = $('#proposal_debt_authorization_bank_account_with_dv').val();

            if ((accountWithDv.trim().length > 3 ) &&
                (BankValidator.isAccountValid(bank, agency, accountWithDv))){

                $('.debt_authorization_bank_account_invalid').remove()

                $('#proposal_debt_authorization_bank_account_with_dv')
                    .removeClass('is-invalid')
                    .addClass('is-valid')

            } else {
                $('.debt_authorization_bank_account_invalid').remove()
                $('.proposal_debt_authorization_bank_account_with_dv')
                    .append('<div class="debt_authorization_bank_account_invalid invalid-feedback">Dados Inválidos</div>')

                $('#proposal_debt_authorization_bank_account_with_dv')
                    .removeClass('is-valid')
                    .addClass('is-invalid')
            }
        })

        $('#proposal_bank_account_type').on('change', (e) => {
            if($('#proposal_bank_account_type').val()){
                $('#proposal_bank_account_type')
                    .removeClass('is-invalid')
                    .addClass('is-valid')
            }else{
                $('#proposal_bank_account_type')
                    .removeClass('is-valid')
                    .addClass('is-invalid')
            }
        })

        $('[id$=_politically_exposed]').on('change', function() {
            if(this.checked) {
                $('[id$=_political_exposure_position]')
                    .prop('disabled', false);
            }

            else {
                $('[id$=_political_exposure_position]')
                    .prop('disabled', true)
                    .val('')
            }
        })

        document.addEventListener("DOMContentLoaded", function() {
            const pep_check_tag = $('[id$=_politically_exposed]').prop('checked')
            if (pep_check_tag){
                $('#div-political_exposure_position')
                    .prop('disabled', false)
            }
        })

        $('#proposal_debt_authorization_bank_account_type').on('change', (e) => {
            if($('#proposal_debt_authorization_bank_account_type').val()){
                $('#proposal_debt_authorization_bank_account_type')
                .removeClass('is-invalid')
                .addClass('is-valid')
            }else{
                $('#proposal_debt_authorization_bank_account_type')
                .removeClass('is-valid')
                .addClass('is-invalid')
            }
        })

        new FormValidation().validationCheckRequiredFields()
    }

    validationCheckRequiredFields(){
        $('#accordionProposal .failed-validation').removeClass('failed-validation')
        $('#accordionProposal .form-control.required').each(function() {
            if($(this).val()==''){
                $(this).parent().parent().parent().parent()
                        .parent().parent().parent()
                        .find('.card-header h4 a')
                        .addClass(['failed-validation', 'required'])
            }
            $(this).on('blur', function(){
                new FormValidation().validationCheckRequiredFields()
            })
        })
    }
}

new FormValidation().valid()

