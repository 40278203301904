import $ from 'jquery'

class OperatorActions {
  constructor() {
    this.bindFormAndButtons()
  }

  bindFormAndButtons = () => {
    $('#dossier_file').on('change', (e) => this.handleDossierChange(e))
  }

  handleDossierChange = (e) => {
    const answer = window.confirm(
      'O Dossiê é um zip contendo:\n'+
      '- CCB Assinada\n'+
      '- Comprovante de Averbação\n'+
      '- Documento de Identidade\n'+
      '- Foto do cliente segurando o documento\n'+
      '- Extrato ou Contracheque\n'+
      '\n Deseja prosseguir com o envio?\n'
    )

    if(!answer) {
      return false
    }

    $('#upload_dossier_form').submit()
    const field = $(e.target)
    field.attr('disabled','disabled')
    field.parent().addClass('-disabled')
    field.parent().text('Enviando dossiê...')
  }
}

$(() => {
  new OperatorActions()
})